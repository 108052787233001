.App {
  padding: 8px;
}

html, body, #root {
  width: 100%;
  height: 100%;
}

.remove-device-btn {
  font-weight: 500 !important;
  font-size: 0.95em !important;
  min-width: 250px;
}

.btn-primary {
  background-color: rgb(30, 162, 130) !important;
  border-color: rgb(30, 162, 130) !important;
}

.btn-primary:hover {
  background-color: rgb(32, 174, 139) !important;
  border-color: rgb(32, 174, 139) !important;
}

.navbarStyle {
  padding: .5rem 1rem !important;
  height: 15%;
  min-height: 70px;
  margin: 0px;
}

.content{
  background-color: rgb(249, 249, 249) !important;
  height: 85%;
}

.title {
  margin: 25px 0px 25px 0px;
}

.deviceContent {
  padding: 5px;
}

.userDevice {
  border: solid 1px;
  border-radius: 3px;
  padding: 10px;
  max-width: 480px;
}

.error {
  color: red;
}

.icon-size {
  width: 40px;
  height: 40px;
}

.container {
  padding: 8px;
}

.device-info-row {
  display: flex;
  align-items: center;
  width: 100%;
}

.btn-row {
  margin-top: 20px;
  width: 100%;
}

.device-info {
  margin-left: 1rem;
}

.device-info p {
  padding: 0;
  margin: 0 0 3px 0 !important;
}

.device-name {
  font-weight: 600;
}

.login-time {
  font-size: 0.8em;
  color: #464646;
  font-weight: 300;
}

@media only screen and (max-width: 576px) {
  .navbar-brand {
    width: 65%;
  }
  .navbar-brand img{
    width: 100%;
  }
}